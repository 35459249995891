<template>
  <div class="main">
    <img src="../../assets/mana/succ.png" alt="" />
    <div>提交成功</div>
    <div>感谢您的参与！</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mouted() {},
  methods: {},
};
</script>
<style scoped>
.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  background: #f8f8f8;
  color: #666666;
}
.main img {
  width: 152px;
  height: 152px;
  margin-top: 83px;
}
.main div:nth-of-type(1) {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  margin: 14px 0 6px 0;

}
</style>